.text-box {
    /* centers */
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.paste-text {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;

    font-size: 24px;
    font-weight: bold;
}

.analyze-button {
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 16px;
    font-weight: bolder;
}
