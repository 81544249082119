.about-text {
    margin-left: 20%;
    margin-right: 20%;
    font-size: 20px;

}

.logo-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 42%;
}
