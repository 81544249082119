.results-text {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1%;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
